/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { OTPRequest, OTPResponse } from "state/otp";
import { addMessage } from "@crossroads/shop-state/messages";
import { SEND_OTP_REQUEST, SET_OTP_REQUEST, SET_OTP_RESPONSE } from "state/otp";
import { logout } from "state/customer";
import { sendSASOTP, setSASOTP } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      try {
        const { sendSASOTP: { result } } = await client(sendSASOTP);

        if (result !== "SUCCESS") {
          storage.broadcastMessage(addMessage(result, "error"));
        }
      }
      catch (e) {
        const error = e.errors[0];

        if (error) {
          if (error.extensions.category === "sas") {
            switch (error.extensions.code) {
              case "invalid_sso_ticket":
                storage.broadcastMessage(addMessage(error.extensions.code, "error"));
                storage.broadcastMessage(logout("/"));
                break;
              default:
                break;
            }
          }
        }
      }
    },
    subscribe: { [SEND_OTP_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: OTPRequest) => {
      if (msg.tag === SET_OTP_REQUEST) {
        await client(setSASOTP, { otp: msg.otp });

        return ({
          tag: SET_OTP_RESPONSE,
        }: OTPResponse);
      }
    },
    subscribe: { [SET_OTP_REQUEST]: true },
  });
};

export default registerClient;
