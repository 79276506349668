/* @flow */

import React, { useContext } from "react";
import { CustomerData, QuoteData } from "data";
import { useData, useSendMessage } from "crustate/react";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import Button from "components/Button";
import Wrapper from "components/Wrapper";
import useFormat from "helpers/use-format";
import { logout } from "@crossroads/shop-state/customer";
import { useUi } from "helpers/ui";
import useCustomer from "helpers/use-customer";
import { MODE } from "state/view-mode";
import { getCustomerPoints } from "state/customer";
import { StoreInfoContext } from "entrypoint/shared";
import { adventCalendarActive } from "state/advent-calendar";
import useAdventCalendarEnabled from "helpers/use-advent-calendar-enabled";
import styles from "./styles.scss";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { languages } from "components/LanguageSwitcher";

import CloseIcon from "icons/close-big.svg";
import ChevronIcon from "icons/chevron.svg";
import SearchIcon from "icons/search.svg";

const Header = () => {
  const t = useTranslate();
  const { closeHamburger: close } = useUi();

  return (
    <Wrapper className={styles.header}>
      <span className={styles.heading}>{t("HEADER.NAVIGATION")}</span>
      <Button onClick={() => close(styles.animationDuration)}>
        <CloseIcon className={styles.close} />
      </Button>
    </Wrapper>
  );
};

const LogoutButton = () => {
  const t = useTranslate();
  const sendMessage = useSendMessage();

  return (
    <span className={styles.item} onClick={() => sendMessage(logout())}>
      <span>{t("HEADER.LOGOUT")}</span>
    </span>
  );
};

const TopMenu = () => {
  const t = useTranslate();
  const location = useLocation();
  const { setModeAndOpenMenu: setMode } = useUi();
  const customer = useData(CustomerData).data || null;
  const loggedIn = customer !== null;
  const { info: { time } } = useContext(StoreInfoContext);
  const testDate = new URLSearchParams(location.search).get("testDate");
  const date = testDate ? new Date(testDate) : new Date(time);
  const showAdventCalendar = adventCalendarActive(date, time);
  const adventCalendarEnabled = useAdventCalendarEnabled();
  const { redirectToLogin } = useCustomer();

  return (
    <div className={styles.topMenu}>
      {adventCalendarEnabled && showAdventCalendar &&
        <Link
          className={styles.item}
          to={t("ADVENT_CALENDAR.LINK")}
        >
          <span>{t("ADVENT_CALENDAR.TEXT")}</span>
        </Link>
      }

      <Link className={styles.item} to="/all-products?sort=created_at&sortOrder=DESC">
        {t("ALL_PRODUCTS.TITLE")}
      </Link>

      <Button className={styles.item} onClick={() => setMode(MODE.CATEGORIES)}>
        <span>{t("HEADER.CATEGORIES")}</span>
        <ChevronIcon className={styles.chevron} />
      </Button>

      <Button className={styles.item} onClick={() => setMode(MODE.BRANDS)}>
        <span>{t("HEADER.BRANDS")}</span>
        <ChevronIcon className={styles.chevron} />
      </Button>

      <Link className={styles.item} to="/gift-cards-vouchers">
        {t("HEADER.GIFTCARDS")}
      </Link>

      {!loggedIn ?
        <Button
          className={cn(styles.item, styles.item__buyandtransfer)}
          onClick={() => redirectToLogin()}
        >
          <span>{t("PURCHASE_POINTS.TITLE")}</span>
        </Button> :
        <Link className={styles.item} to="/account/buy-and-transfer-points">
          {t("PURCHASE_POINTS.TITLE")}
        </Link>
      }

      <Link className={styles.item} to={t("CUSTOMER_SERVICE.LINK")}>
        <span>{t("CUSTOMER_SERVICE.TEXT")}</span>
      </Link>

      <Button className={styles.item} onClick={() => setMode(MODE.SEARCH)}>
        <span>
          <SearchIcon className={styles.searchIcon} />
          <span>{t("HEADER.SEARCH")}</span>
        </span>
      </Button>

      {!loggedIn &&
        <Button
          className={cn(styles.item, styles.item__buyandtransfer)}
          onClick={() => redirectToLogin()}
        >
          <span>{t("HEADER.LOGIN")}</span>
        </Button>
      }

      {(loggedIn && customer?.sasRedemptionEnabled) && <CartButton />}
      {loggedIn && <LogoutButton />}
    </div>
  );
};

const BottomMenu = () => {
  const customerData = useData(CustomerData);
  const customer = customerData.state === "LOGGED_IN" ||
                   customerData.state === "UPDATING" ? customerData.data : null;
  const { formatPoints } = useFormat();
  const { info: { locale } } = useContext(StoreInfoContext);
  const lang = languages.find(x => x.lang === locale.replace(/_/, "-")) || languages[0];
  const t = useTranslate();
  const loggedIn = customer !== null;

  return (
    <Wrapper className={styles.bottomMenu}>
      {loggedIn && customer &&
      <div className={styles.row}>
        <div><Link className={styles.link} to="/account">{t("HEADER.MY_ACCOUNT")}</Link></div>
        <div>{formatPoints(getCustomerPoints(customer))}</div>
      </div>}

      {loggedIn &&
        <div className={styles.row}>
          <div className={styles.link}>{t("HEADER.DELIVER_TO")}</div>
          <div>{lang.title}</div>
        </div>
      }

      <div className={styles.row}>
        <a
          className={styles.link}
          href="https://sas.se"
          target="_blank" rel="noopener noreferrer"
        >
          {t("HEADER.GO_TO_SAS")}
        </a>
        <div />
      </div>
    </Wrapper>
  );
};

const CartButton = () => {
  const t = useTranslate();
  const { formatPoints } = useFormat();
  const quote = useData(QuoteData).data || null;
  const grandTotal = quote?.grandTotal.incVat || 0;
  const cartCount = quote?.items.reduce((a, { qty }) => a + qty, 0) || 0;

  return (
    <Link
      className={styles.item}
      title={t("HEADER.CART")}
      to="/checkout"
    >
      <span>{t("HEADER.CART")}</span>
      <span>
        <span className={styles.grandTotal}>
          {formatPoints(grandTotal)}&nbsp;
        </span>
        <span className={styles.cartCount}>({cartCount})</span>
      </span>
    </Link>
  );
};

const HamburgerMenu = ({ children }: { children: React$Node }) => {
  const {
    hamburgerOpen,
    isHamburgerOpening,
    isHamburgerClosing,
    subNavOpen,
  } = useUi();

  const ref = React.useRef();

  React.useEffect(() => {
    const ele = ref.current;
    if (hamburgerOpen && !subNavOpen) {
      disableBodyScroll(ele);
    }
    else {
      enableBodyScroll(ele);
    }

    return () => {
      if (ele) {
        enableBodyScroll(ele);
      }
    };
  }, [ref.current, hamburgerOpen]);

  return (
    <div className={cn(
      styles.block,
      { [styles.open]: hamburgerOpen },
      { [styles.isOpening]: isHamburgerOpening },
      { [styles.isClosing]: isHamburgerClosing },
      { [styles.subNavOpen]: subNavOpen }
    )}
    >
      <div ref={ref} className={styles.hamburgerMenu}>
        <Header />

        <TopMenu />
        <BottomMenu />
      </div>

      {children}
    </div>
  );
};

export default HamburgerMenu;
